
































































import { Component, Prop } from 'vue-property-decorator';
import { dispatchGetPackageById, dispatchGetPanelsForList, dispatchUpdatePackage } from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IPanel } from '@/interfaces/panels';
import { ICreatePackage, IPackage } from '@/interfaces/packages';


@Component({})
export default class EditPackage extends AppComponent {
  @Prop({ type: String }) public packageId: string;

  public valid = false;

  /*
    package data
  */
  public name: string = '';
  public version: string = '';
  public description: string = '';

  private selectedPanels: string[] = []; // panels selected by user

  /*
    package data
  */
  private package: IPackage;


  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    if (this.packageId !== undefined && this.packageId.length > 0) {
      this.package = await dispatchGetPackageById(this.$store, this.packageId);
      if (this.package) {
        await this.fetchData();
        this.reset();
      } else {
        this.$router.back();
      }
    } else {
      this.$router.back();
    }
    this.setAppLoading(false);
  }

  /**
   * it starts the screen by doing requests
   * to server to feed the view
   */
  public async fetchData() {
    await dispatchGetPanelsForList(this.$store);
  }

  /*
    It resets the screen by charging the data / model values with the
    values from the package gotten from Store.
    It also reset the form validations
  */
  public reset() {
    this.$validator.reset();
    if (this.package) {
      this.name = this.package.name || '';
      this.version = this.package.version || '';
      this.description = this.package.description || '';
      const panels = this.package.panels as IPanel[] || [];
      this.selectedPanels = panels.map((panel) => panel.id!) || [];
    }
    this.$validator.reset();
  }

  /**
   * It cancels the operation and redirect to Admin packages
   */
  public cancel() {
    this.$router.back();
  }

  /*
    It Submits the package update by checking if the model values have values
    If they dont, they are not included in the API call
  */
  public async submit() {
    if (await this.$validator.validateAll()) {
      const protoPackage = {} as IPackage;

      this.setFieldIfChanges(protoPackage, 'name', this.name, this.package.name);
      this.setFieldIfChanges(protoPackage, 'version', this.version, this.package.version);
      this.setFieldIfChanges(protoPackage, 'description', this.description, this.package.description);

      // check if packages did change
      if (this.package.panels && this.package.panels.length > 0) {
        const originalPanels: string [] = [];
        if (this.package.panels && this.package.panels.length > 0) {
          this.package.panels.forEach((panel) => {
            originalPanels.push(panel.id! || '');
          });
        }

        if (this.isListDifferent(originalPanels, this.selectedPanels)) {
          protoPackage.panels = this.selectedPanels;
        }
      } else {
        if (this.selectedPanels && this.selectedPanels.length > 0) {
          protoPackage.panels = this.selectedPanels;
        }
      }

      const updatePackage = protoPackage as ICreatePackage;
      /**
       * if there is at least one field to update, then we call the API
       */
      if (Object.keys(updatePackage).length > 0) {
        await dispatchUpdatePackage(this.$store, {packageId: this.packageId, package: updatePackage});
        this.$router.push('/main/content/packages');
      }

    }
  }

}
